import ContentLabelSelectorDropdownListItem from "@/content-usage/drawer/label/ContentLabelSelectorDropdownListItem"
import { ContentLabelSelectorDropdownListFragment$key } from "@/content-usage/drawer/label/__generated__/ContentLabelSelectorDropdownListFragment.graphql"
import { ContentLabelSelectorDropdownList_CreateLabelMutation } from "@/content-usage/drawer/label/__generated__/ContentLabelSelectorDropdownList_CreateLabelMutation.graphql"
import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoIcon,
  DiscoIconButton,
  DiscoInput,
} from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

export type ContentLabelSelection = {
  id: GlobalID
  name?: string | null
}

interface ContentLabelSelectorDropdownListProps {
  form: ContentFormStore
  handleClose: VoidFunction
  organizationKey: ContentLabelSelectorDropdownListFragment$key
}

function ContentLabelSelectorDropdownList({
  form,
  handleClose,
  organizationKey,
}: ContentLabelSelectorDropdownListProps) {
  const organization = useFragment<ContentLabelSelectorDropdownListFragment$key>(
    graphql`
      fragment ContentLabelSelectorDropdownListFragment on Organization {
        id
        contentLabels {
          edges {
            node {
              id
              ...ContentLabelSelectorDropdownListItemFragment
            }
          }
        }
      }
    `,
    organizationKey
  )

  const [isCreatingNewType, setIsCreatingNewType] = useState<boolean>(false)

  const createLabelForm =
    useFormStore<ContentLabelSelectorDropdownList_CreateLabelMutation>(
      graphql`
        mutation ContentLabelSelectorDropdownList_CreateLabelMutation(
          $input: CreateContentLabelInput!
        ) {
          response: createContentLabel(input: $input) {
            node {
              id
              label
              organization {
                contentLabels {
                  edges {
                    node {
                      id
                      label
                      ...ContentLabelSelectorDropdownListItemFragment
                    }
                  }
                }
              }
            }
            errors {
              field
              message
            }
          }
        }
      `,
      {
        organizationId: organization.id,
        label: "",
      }
    )

  const contentLabels = Relay.connectionToArray(organization.contentLabels)
  const classes = useStyles()

  return (
    <>
      {contentLabels.map((contentLabel) => (
        <ContentLabelSelectorDropdownListItem
          key={module.id}
          testid={`ContentLabelSelectorDropdownList`}
          form={form}
          handleClose={handleClose}
          contentLabelKey={contentLabel}
        />
      ))}

      {isCreatingNewType ? (
        <div className={classes.createContainer}>
          <DiscoInput
            value={createLabelForm.state.label}
            onChange={handleLabelChange}
            data-testid={`CreateContentLabel`}
            fullWidth
          />

          <div className={classes.createButtonsContainer}>
            <DiscoIconButton
              size={"small"}
              variant={"outlined"}
              testid={`CreateContentLabel.confirm-button`}
              onClick={confirmCreate}
              height={40}
              width={40}
              shouldDisplaySpinner={createLabelForm.isSubmitting}
            >
              <DiscoIcon icon={"check"} />
            </DiscoIconButton>

            <DiscoIconButton
              size={"small"}
              variant={"outlined"}
              testid={`CreateContentLabel.cancel-button`}
              onClick={cancelCreate}
              height={40}
              width={40}
            >
              <DiscoIcon icon={"close"} />
            </DiscoIconButton>
          </div>
        </div>
      ) : (
        <DiscoButton
          testid={"CreateContentLabel.create-button"}
          color={"grey"}
          variant={"outlined"}
          onClick={startCreate}
          leftIcon={"add"}
        >
          {"New Content Label"}
        </DiscoButton>
      )}
    </>
  )

  function startCreate() {
    setIsCreatingNewType(true)
  }

  function handleLabelChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    createLabelForm.state.label = e.target.value
  }

  async function confirmCreate() {
    const { didSave, response } = await createLabelForm.submit(createLabelForm.state)
    if (!didSave || !response?.node) return

    displaySuccessToast({
      testid: `CreateContentLabel.success-toast`,
      message: "Label created!",
    })
    setIsCreatingNewType(false)
  }

  function cancelCreate() {
    setIsCreatingNewType(false)
    createLabelForm.state.label = ""
  }
}

const useStyles = makeUseStyles((theme) => ({
  createContainer: {
    height: "48px",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  createButtonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))

function ContentLabelSelectorDropdownListSkeleton() {
  return (
    <>
      <DiscoButtonSkeleton />
      <DiscoButtonSkeleton />
      <DiscoButtonSkeleton />
      <DiscoButtonSkeleton />
    </>
  )
}

export default Relay.withSkeleton({
  component: observer(ContentLabelSelectorDropdownList),
  skeleton: ContentLabelSelectorDropdownListSkeleton,
})
