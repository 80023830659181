import { ContentType } from "@/admin/content-library/__generated__/AdminContentLibraryListTableRowFragment.graphql"
import ContentLabelSelectorDropdownListItemOverflow from "@/content-usage/drawer/label/ContentLabelSelectorDropdownListItemOverflow"
import ContentLabelUpdateForm from "@/content-usage/drawer/label/ContentLabelUpdateForm"
import { ContentLabelSelectorDropdownListItemFragment$key } from "@/content-usage/drawer/label/__generated__/ContentLabelSelectorDropdownListItemFragment.graphql"
import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { DiscoChip, DiscoIcon, DiscoTooltip } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { capitalize, darken, useTheme } from "@material-ui/core"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { stringifyArray } from "@utils/string/stringUtils"
import { TestIDProps } from "@utils/typeUtils"
import classnames from "classnames"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  form: ContentFormStore
  handleClose: VoidFunction
  contentLabelKey: ContentLabelSelectorDropdownListItemFragment$key
}

function ContentLabelSelectorDropdownListItem({
  testid,
  form,
  handleClose,
  contentLabelKey,
}: Props) {
  const contentLabel = useFragment<ContentLabelSelectorDropdownListItemFragment$key>(
    graphql`
      fragment ContentLabelSelectorDropdownListItemFragment on ContentLabel {
        id
        label
        defaultContentTypes
        ...ContentLabelUpdateFormFragment
        ...ContentLabelSelectorDropdownListItemOverflowFragment
      }
    `,
    contentLabelKey
  )

  const [isEditing, setIsEditing] = useState<boolean>(false)

  const theme = useTheme()
  const classes = useStyles()

  const showOnHoverHideClasses = useShowOnHoverStyles() // Hides the element
  const showOnHoverUnrenderClasses = useShowOnHoverStyles({
    // Unrenders the element
    hideWithDisplay: { stylesWhenShown: { display: "block" } },
  })

  const TESTID = `${testid}.item.${contentLabel.label}`
  const defaultContentTypes = JSON.parse(
    contentLabel.defaultContentTypes
  ) as ContentType[]

  // Hide survey label unless FF is turned on
  const { surveys } = useFeatureFlags()
  if (!surveys && defaultContentTypes.includes("survey")) return null

  if (isEditing) {
    return (
      <ContentLabelUpdateForm
        testid={testid}
        contentLabelKey={contentLabel}
        onClose={cancelEdit}
      />
    )
  }

  const isSelected = form.state.content.contentLabelId === contentLabel.id

  if (isSelected) {
    return (
      <div
        data-testid={TESTID}
        className={classnames(
          classes.isSelectedContainer,
          showOnHoverUnrenderClasses.hoverable
        )}
      >
        <DiscoTag
          name={contentLabel.label.toUpperCase()}
          color={theme.palette.primary.main}
          backgroundColor={
            theme.palette.type === "light"
              ? theme.palette.primary.light
              : darken(theme.palette.primary.dark, 0.5)
          }
          className={classes.isSelectedTag}
        />
        <div className={classes.buttonContainer}>
          <DiscoIcon
            marginRight={0.5}
            marginLeft={0.5}
            className={showOnHoverUnrenderClasses.hidable}
            icon={"check"}
            color={theme.palette.primary.main}
            width={24}
            height={24}
          />
          <ContentLabelSelectorDropdownListItemOverflow
            testid={TESTID}
            className={showOnHoverUnrenderClasses.showable}
            contentLabelKey={contentLabel}
            onClickEdit={startEdit}
            hideDeleteButton // Can't delete the label that's currently selected
          />
          {renderDefaultLabelTooltip()}
        </div>
      </div>
    )
  }

  return (
    <DiscoDropdownItem
      key={contentLabel.id}
      testid={TESTID}
      onClick={handleSelectLabel}
      className={showOnHoverHideClasses.hoverable}
      title={
        <DiscoChip
          className={classes.titleChip}
          label={contentLabel.label.toUpperCase()}
          color={"grey"}
        />
      }
      rightButton={
        <div className={classes.buttonContainer}>
          <ContentLabelSelectorDropdownListItemOverflow
            testid={TESTID}
            className={showOnHoverHideClasses.showable}
            contentLabelKey={contentLabel}
            onClickEdit={startEdit}
          />
          {renderDefaultLabelTooltip()}
        </div>
      }
    />
  )

  function renderDefaultLabelTooltip() {
    if (defaultContentTypes.length === 0) return null

    const types = defaultContentTypes.map((contentType) => {
      if (contentType === "custom") return "Task"
      return capitalize(contentType).replace(/_/g, " ")
    })

    return (
      <DiscoTooltip content={`Default label for ${stringifyArray(types)} content.`} />
    )
  }

  function startEdit() {
    setIsEditing(true)
  }

  function cancelEdit() {
    setIsEditing(false)
  }

  function handleSelectLabel() {
    form.state.content.contentLabelId = contentLabel.id
    handleClose()
  }
}

const useStyles = makeUseStyles((theme) => ({
  titleChip: {
    cursor: "pointer",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  isSelectedContainer: {
    height: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    padding: `0 ${theme.spacing(1)}px`,
  },
  isSelectedTag: {
    borderRadius: theme.measure.borderRadius.xxl,
  },
}))

export default observer(ContentLabelSelectorDropdownListItem)
