import { WebFormSubmissionsUtils } from "@/web-form/utils/webFormSubmissionsUtils"
import DiscoTableFilterTags from "@disco-ui/table/header/filter/DiscoTableFilterTags"
import DiscoTag from "@disco-ui/tag/DiscoTag"

function QuizSubmissionsTableFilterTags() {
  const { toolbarState, handleRemoveFilter } = WebFormSubmissionsUtils.useToolbarState()

  const showFilterTags = Boolean(
    Object.values(toolbarState.filterBy).filter(Boolean).length
  )

  return (
    <DiscoTableFilterTags isVisible={showFilterTags}>
      {toolbarState.filterBy.status && (
        <DiscoTag
          name={toolbarState.filterBy.status.title}
          onDelete={() => handleRemoveFilter("status")}
        />
      )}
      {toolbarState.filterBy.completed && (
        <DiscoTag
          name={toolbarState.filterBy.completed.title}
          onDelete={() => handleRemoveFilter("completed")}
        />
      )}
      {toolbarState.filterBy.result && (
        <DiscoTag
          name={toolbarState.filterBy.result.title}
          onDelete={() => handleRemoveFilter("result")}
        />
      )}
    </DiscoTableFilterTags>
  )
}

export default QuizSubmissionsTableFilterTags
