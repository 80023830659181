import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawerHeader } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface QuizDrawerQuestionsHeaderProps extends TestIDProps {
  title?: string
  overlineTitle?: string
  onClose: VoidFunction
  onBack: VoidFunction
  customDivider?: React.ReactNode
  shouldDisplayBackSpinner?: boolean
}

function QuizDrawerQuestionsHeader({
  testid = "QuizDrawerQuestionsHeader",
  title = "Untitled",
  overlineTitle = "Quiz",
  onClose,
  onBack,
  customDivider,
  shouldDisplayBackSpinner,
}: QuizDrawerQuestionsHeaderProps) {
  const classes = useStyles()
  return (
    <DiscoDrawerHeader
      testid={`${testid}.header`}
      title={title}
      overlineTitle={overlineTitle}
      onClose={onClose}
      onBack={onBack}
      className={classes.drawerHeader}
      customDivider={customDivider}
      shouldDisplayBackSpinner={shouldDisplayBackSpinner}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  drawerHeader: {
    padding: theme.spacing(2.5),
    paddingBottom: 0,
  },
}))

export default QuizDrawerQuestionsHeader
