import QuizSubmissionsDetailSubmissionsListSidebarItem from "@/content-usage/drawer/quizzes/review-submissions/QuizSubmissionsDetailSubmissionsListSidebarItem"
import QuizSubmissionsFilterOptions from "@/content-usage/drawer/quizzes/review-submissions/QuizSubmissionsFilterOptions"
import QuizSubmissionsOrderOptions from "@/content-usage/drawer/quizzes/review-submissions/QuizSubmissionsOrderOptions"
import FilterFunnel from "@/core/ui/iconsax/linear/filter.svg"
import { QuizRevision } from "@/web-form/utils/webFormQueryUtils"
import { WebFormSubmissionsUtils } from "@/web-form/utils/webFormSubmissionsUtils"
import FilterLines from "@assets/iconsax/linear/sort.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import {
  DiscoDivider,
  DiscoDropdown,
  DiscoEmptyState,
  DiscoIconButton,
  DiscoText,
} from "@disco-ui"
import DiscoExpandButton from "@disco-ui/button/DiscoExpandButton"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import DiscoTableSearchInput, {
  DiscoTableSearchInputSkeleton,
} from "@disco-ui/table/header/search/DiscoTableSearchInput"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { Collapse, List, useTheme } from "@material-ui/core"
import { CSSProperties } from "@material-ui/core/styles/withStyles"
import { range } from "@utils/array/arrayUtils"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { useEffect } from "react"

interface QuizSubmissionsDetailSubmissionsListSidebarProps extends TestIDProps {
  submissions: WebFormSubmissionsUtils.WebFormSubmissionData[]
  revision: QuizRevision

  toolbarState: WebFormSubmissionsUtils.ToolbarState
  toolbarHandlers: Pick<
    ReturnType<typeof WebFormSubmissionsUtils.useToolbarState>,
    | "handleSearchChange"
    | "handleFilterBy"
    | "handleOrderBy"
    | "handleRemoveFilter"
    | "handleRemoveSubmissionIds"
  >
  hasNext: boolean
  scrolledIntoViewProps: {
    onScrolledIntoView: VoidFunction
    isLoading: boolean
    skeleton?: JSX.Element
  }
  width?: CSSProperties["width"]
  showResult: boolean
}

const QUIZ_SUBMISSIONS_LIST_SIDEBAR_WIDTH = 350

function QuizSubmissionsDetailSubmissionsListSidebar({
  testid = "QuizSubmissionsDetailSubmissionsListSidebar",
  submissions,
  revision,
  toolbarState,
  toolbarHandlers: {
    handleSearchChange,
    handleOrderBy,
    handleFilterBy,
    handleRemoveFilter,
    handleRemoveSubmissionIds,
  },
  hasNext,
  scrolledIntoViewProps,
  width = QUIZ_SUBMISSIONS_LIST_SIDEBAR_WIDTH,
  showResult,
}: QuizSubmissionsDetailSubmissionsListSidebarProps) {
  const hasFilters = Boolean(Object.values(toolbarState.filterBy).filter(Boolean).length)
  const theme = useTheme()
  const isMobile = useIsMobile()
  const { isOpen, onToggle } = useDisclosure(!isMobile)
  const classes = useStyles({ width })

  useEffect(() => {
    if (toolbarState.search && submissions.length && !isOpen) onToggle()
  }, [isOpen, onToggle, submissions.length, toolbarState.search])

  return (
    <div data-testid={`${testid}.container`} className={classes.sideBar}>
      <div className={classes.sidebarHeader}>
        <div className={classes.searchSortFilter}>
          {typeof toolbarState.search === "string" && (
            <DiscoTableSearchInput
              testid={`${testid}.search`}
              onChange={handleSearchChange}
              defaultValue={toolbarState.search}
            />
          )}

          <DiscoDropdown
            menuButton={({ onClick }) => (
              <DiscoIconButton
                testid={`${testid}.filter.select`}
                onClick={onClick}
                size={isMobile ? "small" : "medium"}
              >
                <FilterFunnel
                  color={toolbarState.filterBy ? theme.palette.text.primary : undefined}
                />
              </DiscoIconButton>
            )}
          >
            <QuizSubmissionsFilterOptions
              testid={testid}
              toolbarState={toolbarState}
              handleFilterBy={handleFilterBy}
              showResult={showResult}
            />
          </DiscoDropdown>

          <DiscoDropdown
            menuButton={({ onClick }) => (
              <DiscoIconButton
                testid={`${testid}.sort.select`}
                onClick={onClick}
                size={isMobile ? "small" : "medium"}
              >
                <FilterLines
                  color={toolbarState.orderBy ? theme.palette.text.primary : undefined}
                />
              </DiscoIconButton>
            )}
          >
            <QuizSubmissionsOrderOptions
              testid={testid}
              toolbarState={toolbarState}
              handleOrderBy={handleOrderBy}
              showResult={showResult}
            />
          </DiscoDropdown>
          {isMobile && (
            <DiscoExpandButton
              isOpen={isOpen}
              onClick={onToggle}
              size={isMobile ? "small" : "medium"}
            />
          )}
        </div>
        <div className={classes.filterTags}>
          {(hasFilters || !!toolbarState.submissionIds?.length) && (
            <>
              <DiscoText variant={"body-sm"}>{"Filter by: "}</DiscoText>
              {toolbarState.filterBy.status && (
                <DiscoTag
                  testid={`${testid}.filter-tag.status`}
                  name={toolbarState.filterBy.status.title}
                  onDelete={() => handleRemoveFilter("status")}
                />
              )}
              {toolbarState.filterBy.completed && (
                <DiscoTag
                  testid={`${testid}.filter-tag.completion`}
                  name={toolbarState.filterBy.completed.title}
                  onDelete={() => handleRemoveFilter("completed")}
                />
              )}
              {toolbarState.filterBy.result && (
                <DiscoTag
                  testid={`${testid}.filter-tag.result`}
                  name={toolbarState.filterBy.result.title}
                  onDelete={() => handleRemoveFilter("result")}
                />
              )}
              {!!toolbarState.submissionIds?.length && (
                <DiscoTag
                  testid={`${testid}.filter-tag.id`}
                  name={"Id"}
                  onDelete={handleRemoveSubmissionIds}
                />
              )}
            </>
          )}
        </div>
      </div>
      <ScrollShadowContainer classes={{ parentContainer: classes.scrollShadowParent }}>
        {isOpen && <DiscoDivider style={{ margin: 0 }} />}
        <Collapse in={isOpen} style={{ width: "100%" }}>
          <List
            data-testid={`${testid}.list`}
            component={"nav"}
            classes={{ padding: classes.listPadding }}
          >
            {submissions.length ? (
              submissions.map((submission) => (
                <QuizSubmissionsDetailSubmissionsListSidebarItem
                  key={submission.id}
                  submission={submission}
                  revision={revision}
                />
              ))
            ) : (
              <DiscoEmptyState
                testid={`${testid}.empty-state`}
                title={"No Submissions"}
                subtitle={`There are no submissions${
                  hasFilters || toolbarState.search
                    ? " that match the selected criteria"
                    : ""
                }`}
              />
            )}
            {hasNext && <DiscoScrolledIntoView {...scrolledIntoViewProps} />}
          </List>
        </Collapse>
      </ScrollShadowContainer>
    </div>
  )
}

function Skeleton() {
  const classes = useStyles({ width: QUIZ_SUBMISSIONS_LIST_SIDEBAR_WIDTH })
  return (
    <div className={classes.sideBar}>
      <div className={classes.sidebarHeader}>
        <div className={classes.searchSortFilter}>
          <DiscoTableSearchInputSkeleton />
          <DiscoIconButton>
            <FilterFunnel />
          </DiscoIconButton>
          <DiscoIconButton>
            <FilterLines />
          </DiscoIconButton>
        </div>
      </div>
      <div className={classes.scrollShadowParent}>
        <div className={classes.listPadding}>
          {range(20).map((key) => (
            <QuizSubmissionsDetailSubmissionsListSidebarItem.Skeleton key={key} />
          ))}
        </div>
      </div>
    </div>
  )
}
type StyleProps = {
  width: CSSProperties["width"]
}

const useStyles = makeUseStyles((theme) => ({
  sideBar: {
    display: "grid",
    overflow: "hidden",
    height: "100%",
    width: ({ width }: StyleProps) => width,
    gridTemplateRows: "auto 1fr",
    backgroundColor: theme.palette.background.paper,
  },
  sidebarHeader: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1.5),
    width: "100%",
  },
  searchSortFilter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(0.5),
    width: "100%",
    marginBottom: theme.spacing(0.5),
  },
  filterTags: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: theme.spacing(0.5),
  },
  listPadding: {
    padding: theme.spacing(1.5),
    width: "100%",
  },
  scrollShadowParent: {
    width: "100%",
    height: "inherit",
  },
}))

QuizSubmissionsDetailSubmissionsListSidebar.Skeleton = Skeleton
export default QuizSubmissionsDetailSubmissionsListSidebar
