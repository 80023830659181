/**
 * @generated SignedSource<<b3d2c31c2436ab736ca8b4117b4ef39e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentLabelUpdateFormFragment$data = {
  readonly id: string;
  readonly label: string;
  readonly " $fragmentType": "ContentLabelUpdateFormFragment";
};
export type ContentLabelUpdateFormFragment$key = {
  readonly " $data"?: ContentLabelUpdateFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentLabelUpdateFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentLabelUpdateFormFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "type": "ContentLabel",
  "abstractKey": null
};

(node as any).hash = "ed4131b59a39af4dc8e8b4524e2f7d59";

export default node;
