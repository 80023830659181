import QuizReviewSubmissionsDrawerHeader from "@/content-usage/drawer/quizzes/review-submissions/QuizReviewSubmissionsDrawerHeader"
import QuizSubmissionsDetailContent from "@/content-usage/drawer/quizzes/review-submissions/QuizSubmissionsDetailContent"
import QuizSubmissionsOverviewContent from "@/content-usage/drawer/quizzes/review-submissions/QuizSubmissionsOverviewContent"
import QuizSubmissionsQuestionReportsList from "@/content-usage/drawer/quizzes/review-submissions/QuizSubmissionsQuestionReportsList"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { ContentType } from "@/content-usage/drawer/__generated__/InlineContentDrawerTemplateFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import { TestIDProps } from "@utils/typeUtils"

export type QuizSubmissionsDrawerContentProps = {
  contentUsageId: GlobalID
  revision: {
    id: GlobalID
    webFormId: GlobalID
  }
}

interface ContentUsageQuizSubmissionsDrawerContentProps
  extends TestIDProps,
    QuizSubmissionsDrawerContentProps {
  content: {
    name: string | null
    type: ContentType
  }
  contentLabel: string
  membersOnly?: boolean
}

function ContentUsageQuizSubmissionsDrawerContent({
  testid = "ContentUsageQuizSubmissionsDrawerContent",
  contentUsageId,
  revision,
  content,
  contentLabel,
  membersOnly,
}: ContentUsageQuizSubmissionsDrawerContentProps) {
  const drawer = useContentUsageDrawer()
  const quizDrawerTab = drawer.params.drawerQuizTab
  if (!quizDrawerTab) return null

  return (
    <>
      <QuizReviewSubmissionsDrawerHeader
        testid={`${testid}.header`}
        title={content.name ?? "Untitled"}
        overlineTitle={"Results"}
      />
      {renderTabs()}
    </>
  )
  function renderTabs() {
    switch (quizDrawerTab) {
      case "submissions-detail":
        return (
          <QuizSubmissionsDetailContent
            contentLabel={contentLabel}
            contentUsageId={contentUsageId}
            revision={revision}
            showResult={content.type !== "survey"}
            membersOnly={membersOnly}
          />
        )
      case "submissions-summaries":
        return (
          <QuizSubmissionsQuestionReportsList
            contentUsageId={contentUsageId}
            revisionId={revision.id}
          />
        )
      default:
        return (
          <QuizSubmissionsOverviewContent
            contentLabel={contentLabel}
            contentUsageId={contentUsageId}
            revision={revision}
            contentType={content.type}
            membersOnly={membersOnly}
          />
        )
    }
  }
}

export default ContentUsageQuizSubmissionsDrawerContent
