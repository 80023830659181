/**
 * @generated SignedSource<<a90a161b2962092e556680176284e906>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentLabelSelectorDropdownListItemOverflowFragment$data = {
  readonly id: string;
  readonly defaultContentTypes: string;
  readonly " $fragmentType": "ContentLabelSelectorDropdownListItemOverflowFragment";
};
export type ContentLabelSelectorDropdownListItemOverflowFragment$key = {
  readonly " $data"?: ContentLabelSelectorDropdownListItemOverflowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentLabelSelectorDropdownListItemOverflowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentLabelSelectorDropdownListItemOverflowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultContentTypes",
      "storageKey": null
    }
  ],
  "type": "ContentLabel",
  "abstractKey": null
};

(node as any).hash = "d1c17f158a421a29fcbc799d383fb1d8";

export default node;
