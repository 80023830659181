import { DeleteContentLabelButtonMutation } from "@/content-usage/drawer/label/__generated__/DeleteContentLabelButtonMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoConfirmationModal from "@disco-ui/modal/DiscoConfirmationModal"
import { useTheme } from "@material-ui/core"
import usePermissions from "@utils/hook/usePermissions"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { graphql } from "relay-runtime"

interface Props extends OverridableDiscoButtonProps {
  contentLabelId: GlobalID
}

function DeleteContentLabelButton(props: Props) {
  const { contentLabelId, children, ...rest } = props
  const activeOrganization = useActiveOrganization()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const form = useFormStore<DeleteContentLabelButtonMutation>(
    graphql`
      mutation DeleteContentLabelButtonMutation($input: DeleteContentLabelInput!) {
        response: deleteContentLabel(input: $input) {
          deletedContentLabelId @deleteRecord
          errors {
            field
            message
          }
        }
      }
    `,
    {
      contentLabelId,
    }
  )

  const theme = useTheme()
  const permissions = usePermissions(activeOrganization)
  if (!permissions.has("content.manage")) return null

  return (
    <>
      <OverridableDiscoButton {...rest} onClick={openModal}>
        {children}
      </OverridableDiscoButton>

      <DiscoConfirmationModal
        testid={"DeleteContentLabelConfirmationModal"}
        isOpen={isModalOpen}
        zIndex={theme.zIndex.modal + theme.zIndex.raise1}
        onClose={closeModal}
        title={`Are you sure you want to delete this label?`}
        disableTypeToConfirm
        confirmButtonProps={{
          onClick: deleteContentLabel,
          shouldDisplaySpinner: form.isSubmitting,
          children: "Yes, delete this label",
        }}
        modalContentLabel={"Delete ContentLabel Confirmation Modal"}
        description={
          "Are you sure you want to delete this label? This will be lost forever."
        }
      />
    </>
  )

  async function deleteContentLabel() {
    const { didSave, response } = await form.submit(form.state)
    if (!didSave || !response) return

    displaySuccessToast({
      message: `Label deleted`,
      testid: `DeleteContentLabelButton.success-toast`,
    })
  }

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }
}

export default observer(DeleteContentLabelButton)
