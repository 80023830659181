import QuizDrawerQuestionsFooter from "@/content-usage/drawer/quizzes/QuizDrawerQuestionsFooter"
import { QuizQuestionProps } from "@/content-usage/drawer/quizzes/editor/QuizEditorDrawerContent"
import QuizEditorValidationButton from "@/content-usage/drawer/quizzes/editor/QuizEditorValidationButton"
import { useLabel } from "@/core/context/LabelsContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface QuizEditorDrawerFooterProps extends TestIDProps, QuizQuestionProps {
  readOnly?: boolean
  contentLabel: string
}

function QuizEditorDrawerFooter({
  testid = "QuizEditorDrawerFooter",
  form,
  readOnly,
  contentLabel,
}: QuizEditorDrawerFooterProps) {
  const memberLabel = useLabel("admin_member")
  const classes = useStyles()
  const theme = useTheme()

  if (readOnly)
    return (
      <QuizDrawerQuestionsFooter
        testid={testid}
        classes={{ footer: classes.readOnlyFooter }}
      >
        <DiscoIcon icon={"info"} color={theme.palette.groovy.yellow[600]} />
        <DiscoText
          color={"groovy.yellow.600"}
          variant={"body-md-600"}
        >{`You can no longer edit the ${contentLabel} questions because ${memberLabel.plural} have started submitting answers.`}</DiscoText>
      </QuizDrawerQuestionsFooter>
    )
  return (
    <QuizDrawerQuestionsFooter
      testid={testid}
      buttons={
        <QuizEditorValidationButton form={form}>{"Done"}</QuizEditorValidationButton>
      }
    />
  )
}
const useStyles = makeUseStyles((theme) => ({
  readOnlyFooter: {
    backgroundColor: theme.palette.groovy.yellow[100],
    borderTop: `2px solid ${theme.palette.groovy.yellow[600]}`,
  },
}))
export default observer(QuizEditorDrawerFooter)
