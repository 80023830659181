/**
 * @generated SignedSource<<d5f18ed8cf6e69cd37583335d3993733>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type WebFormSubmissionsOrderByField = "completed_at" | "score" | "duration" | "user_name" | "created_at" | "%future added value";
export type WebFormSubmissionsOrderByInput = {
  direction: OrderDirection;
  field: WebFormSubmissionsOrderByField;
};
export type QuizSubmissionsTableQuery$variables = {
  id: string;
  contentUsageId?: string | null;
  orderBy?: WebFormSubmissionsOrderByInput | null;
  search?: string | null;
  isComplete?: boolean | null;
  completedAfter?: string | null;
  percentageGreaterThanOrEqual?: number | null;
  percentageLessThan?: number | null;
  isPassed?: boolean | null;
  isFailed?: boolean | null;
  membersOnly?: boolean | null;
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
};
export type QuizSubmissionsTableQuery$data = {
  readonly node: {
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"QuizSubmissionsTable_PaginationFragment">;
  } | null;
};
export type QuizSubmissionsTableQuery = {
  variables: QuizSubmissionsTableQuery$variables;
  response: QuizSubmissionsTableQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "completedAfter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentUsageId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isComplete"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isFailed"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isPassed"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "membersOnly"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "percentageGreaterThanOrEqual"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "percentageLessThan"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v15 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "completedAfter",
    "variableName": "completedAfter"
  },
  {
    "kind": "Variable",
    "name": "contentUsageId",
    "variableName": "contentUsageId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "isComplete",
    "variableName": "isComplete"
  },
  {
    "kind": "Variable",
    "name": "isFailed",
    "variableName": "isFailed"
  },
  {
    "kind": "Variable",
    "name": "isPassed",
    "variableName": "isPassed"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "membersOnly",
    "variableName": "membersOnly"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "percentageGreaterThanOrEqual",
    "variableName": "percentageGreaterThanOrEqual"
  },
  {
    "kind": "Variable",
    "name": "percentageLessThan",
    "variableName": "percentageLessThan"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v19 = [
  (v16/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QuizSubmissionsTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v15/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v16/*: any*/),
              {
                "args": (v17/*: any*/),
                "kind": "FragmentSpread",
                "name": "QuizSubmissionsTable_PaginationFragment"
              }
            ],
            "type": "WebFormRevision",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v3/*: any*/),
      (v11/*: any*/),
      (v14/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v10/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v9/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "QuizSubmissionsTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v15/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v18/*: any*/),
          (v16/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v17/*: any*/),
                "concreteType": "WebFormSubmissionNodeConnection",
                "kind": "LinkedField",
                "name": "submissions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WebFormSubmissionNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WebFormSubmission",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "completedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "durationSeconds",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasPassed",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WebFormSubmissionResult",
                            "kind": "LinkedField",
                            "name": "result",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "score",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "total",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "percentage",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "WebFormAnswer",
                                "kind": "LinkedField",
                                "name": "correctAnswers",
                                "plural": true,
                                "selections": (v19/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "WebFormAnswer",
                                "kind": "LinkedField",
                                "name": "incorrectAnswers",
                                "plural": true,
                                "selections": (v19/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v16/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fullName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatar",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfoObjectType",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v17/*: any*/),
                "filters": [
                  "orderBy",
                  "search",
                  "isComplete",
                  "completedAfter",
                  "percentageGreaterThanOrEqual",
                  "percentageLessThan",
                  "isPassed",
                  "isFailed",
                  "membersOnly"
                ],
                "handle": "connection",
                "key": "QuizSubmissionsTable_submissions",
                "kind": "LinkedHandle",
                "name": "submissions"
              }
            ],
            "type": "WebFormRevision",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7147c398c9b42cd95e868dcc3defd9cc",
    "id": null,
    "metadata": {},
    "name": "QuizSubmissionsTableQuery",
    "operationKind": "query",
    "text": "query QuizSubmissionsTableQuery(\n  $id: ID!\n  $contentUsageId: ID\n  $orderBy: WebFormSubmissionsOrderByInput\n  $search: String\n  $isComplete: Boolean\n  $completedAfter: DateTime\n  $percentageGreaterThanOrEqual: Float\n  $percentageLessThan: Float\n  $isPassed: Boolean\n  $isFailed: Boolean\n  $membersOnly: Boolean\n  $first: Int\n  $after: String\n  $last: Int\n  $before: String\n) {\n  node(id: $id) {\n    __typename\n    ... on WebFormRevision {\n      id\n      ...QuizSubmissionsTable_PaginationFragment_41TTY3\n    }\n    id\n  }\n}\n\nfragment QuizSubmissionsTableRow_submission on WebFormSubmission {\n  id\n  completedAt\n  durationSeconds\n  hasPassed\n  result {\n    score\n    total\n    percentage\n    correctAnswers {\n      id\n    }\n    incorrectAnswers {\n      id\n    }\n  }\n  user {\n    id\n    firstName\n    lastName\n    fullName\n    avatar\n  }\n}\n\nfragment QuizSubmissionsTable_PaginationFragment_41TTY3 on WebFormRevision {\n  id\n  submissions(contentUsageId: $contentUsageId, orderBy: $orderBy, search: $search, isComplete: $isComplete, completedAfter: $completedAfter, percentageGreaterThanOrEqual: $percentageGreaterThanOrEqual, percentageLessThan: $percentageLessThan, isPassed: $isPassed, isFailed: $isFailed, membersOnly: $membersOnly, first: $first, after: $after, last: $last, before: $before) {\n    totalCount\n    edges {\n      cursor\n      node {\n        id\n        ...QuizSubmissionsTableRow_submission\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      startCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ddcf50257a288f952f03eacb953df7de";

export default node;
