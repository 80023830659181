/**
 * @generated SignedSource<<84b03bee8a7bf1fe20aab1ee1435a358>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type WebFormSubmissionsOrderByField = "completed_at" | "score" | "duration" | "user_name" | "created_at" | "%future added value";
export type WebFormSubmissionsOrderByInput = {
  direction: OrderDirection;
  field: WebFormSubmissionsOrderByField;
};
export type QuizSubmissionsExportCsvButtonMutation$variables = {
  webFormRevisionId: string;
  contentUsageId?: string | null;
  orderBy?: WebFormSubmissionsOrderByInput | null;
  search?: string | null;
  isComplete?: boolean | null;
  completedAfter?: string | null;
  percentageGreaterThanOrEqual?: number | null;
  percentageLessThan?: number | null;
  membersOnly?: boolean | null;
};
export type QuizSubmissionsExportCsvButtonMutation$data = {
  readonly response: {
    readonly downloadCSVLink: string | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type QuizSubmissionsExportCsvButtonMutation = {
  variables: QuizSubmissionsExportCsvButtonMutation$variables;
  response: QuizSubmissionsExportCsvButtonMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "completedAfter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentUsageId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isComplete"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "membersOnly"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "percentageGreaterThanOrEqual"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "percentageLessThan"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "webFormRevisionId"
},
v9 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "completedAfter",
        "variableName": "completedAfter"
      },
      {
        "kind": "Variable",
        "name": "contentUsageId",
        "variableName": "contentUsageId"
      },
      {
        "kind": "Variable",
        "name": "isComplete",
        "variableName": "isComplete"
      },
      {
        "kind": "Variable",
        "name": "membersOnly",
        "variableName": "membersOnly"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
      },
      {
        "kind": "Variable",
        "name": "percentageGreaterThanOrEqual",
        "variableName": "percentageGreaterThanOrEqual"
      },
      {
        "kind": "Variable",
        "name": "percentageLessThan",
        "variableName": "percentageLessThan"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "webFormRevisionId",
        "variableName": "webFormRevisionId"
      }
    ],
    "concreteType": "CreateWebFormSubmissionsCsvResponse",
    "kind": "LinkedField",
    "name": "createWebFormSubmissionsCsv",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "downloadCSVLink",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QuizSubmissionsExportCsvButtonMutation",
    "selections": (v9/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v8/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v7/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "QuizSubmissionsExportCsvButtonMutation",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "2c8b3bd632202ef1e4868aaf41754c2f",
    "id": null,
    "metadata": {},
    "name": "QuizSubmissionsExportCsvButtonMutation",
    "operationKind": "mutation",
    "text": "mutation QuizSubmissionsExportCsvButtonMutation(\n  $webFormRevisionId: ID!\n  $contentUsageId: ID\n  $orderBy: WebFormSubmissionsOrderByInput\n  $search: String\n  $isComplete: Boolean\n  $completedAfter: DateTime\n  $percentageGreaterThanOrEqual: Float\n  $percentageLessThan: Float\n  $membersOnly: Boolean\n) {\n  response: createWebFormSubmissionsCsv(webFormRevisionId: $webFormRevisionId, contentUsageId: $contentUsageId, orderBy: $orderBy, search: $search, isComplete: $isComplete, completedAfter: $completedAfter, percentageGreaterThanOrEqual: $percentageGreaterThanOrEqual, percentageLessThan: $percentageLessThan, membersOnly: $membersOnly) {\n    downloadCSVLink\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "352c8c7a880b410d637e64b8a2acbaa9";

export default node;
