import { QuizQuestionProps } from "@/content-usage/drawer/quizzes/editor/QuizEditorDrawerContent"
import useQuizEditorDrawer from "@/content-usage/drawer/quizzes/editor/useQuizEditorDrawer"
import ContentUtils from "@/content/util/contentUtils"
import { useValidateWebFormRevisionInput } from "@/web-form/utils/webFormEditorUtils"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface QuizEditorValidationButtonProps
  extends TestIDProps,
    Omit<OverridableDiscoButtonProps, "form">,
    Pick<QuizQuestionProps, "form"> {}

function QuizEditorValidationButton({
  testid = "QuizEditorValidationButton",
  children = "Done",
  form,
  ...overridableProps
}: QuizEditorValidationButtonProps) {
  const webFormRevision = form.state.content.webFormRevision!
  const { navigateAway } = useQuizEditorDrawer()
  const [validate, isValidating] = useValidateWebFormRevisionInput(
    form,
    webFormRevision,
    ContentUtils.webFormTemplateForContent(form.state.contentType),
    { onSuccess: navigateAway }
  )

  return (
    <OverridableDiscoButton
      {...overridableProps}
      testid={testid}
      onClick={handleDone}
      disabled={isValidating}
      shouldDisplaySpinner={isValidating}
    >
      {children}
    </OverridableDiscoButton>
  )

  function handleDone() {
    if (isValidating) return
    if (
      !form ||
      form.state.mode === "read" ||
      !webFormRevision ||
      // if we're editing, we're only concerned with validating when something's changed
      (form.state.mode === "edit" && !form.changedState.content?.webFormRevision)
    ) {
      return navigateAway()
    }
    validate()
  }
}

export default observer(QuizEditorValidationButton)
