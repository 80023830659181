/**
 * @generated SignedSource<<8700822fed240f06711db83730ec6f55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InlineContentDrawerHeaderOverflowFragment$data = {
  readonly id: string;
  readonly content: {
    readonly name: string | null;
    readonly label: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"DeleteContentUsageButtonFragment" | "usePermissionsFragment">;
  readonly " $fragmentType": "InlineContentDrawerHeaderOverflowFragment";
};
export type InlineContentDrawerHeaderOverflowFragment$key = {
  readonly " $data"?: InlineContentDrawerHeaderOverflowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InlineContentDrawerHeaderOverflowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InlineContentDrawerHeaderOverflowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteContentUsageButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "ef8183cb3fe4e6af43c442b00d0013a2";

export default node;
