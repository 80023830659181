import { ContentUsageEntity } from "@/admin/member-onboarding/__generated__/AdminMemberOnboardingListItemMutation.graphql"
import { ProductAppKind } from "@/content-usage/__generated__/ContentUsageUtils_UseParentEntityContentUsageFragment.graphql"
import { InlineContentDrawerFormStore } from "@/content-usage/drawer/InlineContentDrawerTemplate"
import { GlobalID } from "@/relay/RelayTypes"
import React, { createContext, useContext, useEffect } from "react"

export type ContentUsageDrawerParent = {
  module?: {
    id: GlobalID
    name: string
    parentEntity: ContentUsageEntity
    parentEntityId: GlobalID
  } | null
  productApp?: {
    id: GlobalID
    kind: ProductAppKind
  } | null
  contentDashboardBlock?: {
    id: GlobalID
  } | null
  organization?: { id: GlobalID } | null
}

export const ContentUsageDrawerContext = createContext<{
  contentUsageForm?: InlineContentDrawerFormStore
  setContentUsageForm: React.Dispatch<
    React.SetStateAction<InlineContentDrawerFormStore | undefined>
  >
  drawerParentEntity: ContentUsageDrawerParent | undefined
  setDrawerParentEntity: React.Dispatch<
    React.SetStateAction<ContentUsageDrawerParent | undefined>
  >
}>({
  contentUsageForm: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setContentUsageForm: () => {},
  drawerParentEntity: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDrawerParentEntity: () => {},
})

export function useContentUsageDrawerContext() {
  const context = useContext(ContentUsageDrawerContext)

  const { drawerParentEntity } = context

  const isCurriculum = drawerParentEntity?.module?.parentEntity === "curriculum"

  const isCollection = drawerParentEntity?.module?.parentEntity === "collection"

  const isPageApp = drawerParentEntity?.productApp?.kind === "content"

  const isContentDashboardBlock = Boolean(drawerParentEntity?.contentDashboardBlock)

  const isShareLink = Boolean(drawerParentEntity?.organization)

  const isValidContentDrawer =
    isCurriculum || isCollection || isPageApp || isContentDashboardBlock || isShareLink

  return {
    isCurriculum,
    isCollection,
    isPageApp,
    isContentDashboardBlock,
    isShareLink,
    isValidContentDrawer,
    ...context,
  }
}

export function useSetContentUsageDrawerParentEntity(parent: ContentUsageDrawerParent) {
  const { module, productApp, contentDashboardBlock, organization } = parent
  const { setDrawerParentEntity, drawerParentEntity } = useContentUsageDrawerContext()

  useEffect(() => {
    if (module) {
      if (module.parentEntityId === drawerParentEntity?.module?.parentEntityId) return
      setDrawerParentEntity({ module })
    }

    if (productApp) {
      if (productApp.id === drawerParentEntity?.productApp?.id) return
      setDrawerParentEntity({ productApp })
    }

    if (contentDashboardBlock) {
      if (contentDashboardBlock.id === drawerParentEntity?.contentDashboardBlock?.id)
        return
      setDrawerParentEntity({ contentDashboardBlock })
    }

    if (organization) {
      if (organization.id === drawerParentEntity?.organization?.id) return
      setDrawerParentEntity({ organization })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module, productApp, contentDashboardBlock, organization])
}
