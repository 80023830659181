import DeleteContentUsageButton from "@/content-usage/buttons/DeleteContentUsageButton"
import { InlineContentDrawerHeaderOverflowFragment$key } from "@/content-usage/drawer/__generated__/InlineContentDrawerHeaderOverflowFragment.graphql"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { useTheme } from "@material-ui/core"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface InlineContentDrawerHeaderOverflow extends TestIDProps {
  contentUsageKey: InlineContentDrawerHeaderOverflowFragment$key
}

function InlineContentDrawerHeaderOverflow({
  contentUsageKey,
  testid = "InlineContentDrawer",
}: InlineContentDrawerHeaderOverflow) {
  const theme = useTheme()

  const contentUsage = useFragment<InlineContentDrawerHeaderOverflowFragment$key>(
    graphql`
      fragment InlineContentDrawerHeaderOverflowFragment on ContentUsage {
        id
        content {
          name
          label
        }
        ...DeleteContentUsageButtonFragment
        ...usePermissionsFragment
      }
    `,
    contentUsageKey
  )

  const classes = useStyles()
  const permissions = usePermissions(contentUsage)

  if (!permissions.has("content.manage")) return null

  return (
    <DiscoMoreActionsDropdown
      moreActionsButtonClassName={classes.moreActionsDropdown}
      testid={testid}
      rotateIcon
      // Always mount the children components, even when the dropdown is closed
      // This is necessary so we can trigger the share modal without the user opening the dropdown
      keepMounted
    >
      <DeleteContentUsageButton contentUsageKey={contentUsage}>
        {(buttonProps) => (
          <DiscoDropdownItem
            {...buttonProps}
            title={"Delete Instance"}
            testid={`${testid}.delete-button`}
            icon={"trash"}
            color={theme.palette.groovy.red[500]}
          />
        )}
      </DeleteContentUsageButton>
    </DiscoMoreActionsDropdown>
  )
}

const useStyles = makeUseStyles({
  moreActionsDropdown: {
    height: "48px",
    width: "48px",
  },
})

export default InlineContentDrawerHeaderOverflow
