/**
 * @generated SignedSource<<0c297e23c43e7e994b3c068fdd0572cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentLabelSelectorDropdownQuery$variables = {
  id: string;
};
export type ContentLabelSelectorDropdownQuery$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"ContentLabelSelectorDropdownListFragment">;
  } | null;
};
export type ContentLabelSelectorDropdownQuery = {
  variables: ContentLabelSelectorDropdownQuery$variables;
  response: ContentLabelSelectorDropdownQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentLabelSelectorDropdownQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ContentLabelSelectorDropdownListFragment"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContentLabelSelectorDropdownQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentLabelNodeConnection",
                "kind": "LinkedField",
                "name": "contentLabels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentLabelNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentLabel",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "label",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "defaultContentTypes",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9f56bdbd8d298eb48f7b8e60226f1dda",
    "id": null,
    "metadata": {},
    "name": "ContentLabelSelectorDropdownQuery",
    "operationKind": "query",
    "text": "query ContentLabelSelectorDropdownQuery(\n  $id: ID!\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      ...ContentLabelSelectorDropdownListFragment\n    }\n    id\n  }\n}\n\nfragment ContentLabelSelectorDropdownListFragment on Organization {\n  id\n  contentLabels {\n    edges {\n      node {\n        id\n        ...ContentLabelSelectorDropdownListItemFragment\n      }\n    }\n  }\n}\n\nfragment ContentLabelSelectorDropdownListItemFragment on ContentLabel {\n  id\n  label\n  defaultContentTypes\n  ...ContentLabelUpdateFormFragment\n  ...ContentLabelSelectorDropdownListItemOverflowFragment\n}\n\nfragment ContentLabelSelectorDropdownListItemOverflowFragment on ContentLabel {\n  id\n  defaultContentTypes\n}\n\nfragment ContentLabelUpdateFormFragment on ContentLabel {\n  id\n  label\n}\n"
  }
};
})();

(node as any).hash = "f4d767e844b710d299b94500f115621a";

export default node;
