import ContentCTABannerTemplate, {
  ContentCTABannerTemplateSkeleton,
} from "@/content-usage/drawer/quizzes/ContentCTABannerTemplate"
import useQuizEditorDrawer from "@/content-usage/drawer/quizzes/editor/useQuizEditorDrawer"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import {
  useWebFormContentUsageQuery,
  useWebFormSubmissionsQuery,
} from "@/web-form/utils/webFormQueryUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoText, DiscoTooltip } from "@disco-ui"
import usePermissions from "@utils/hook/usePermissions"
import { usePermissionsFragment$key } from "@utils/hook/__generated__/usePermissionsFragment.graphql"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"

interface QuizContentBannerProps extends TestIDProps {
  contentUsageKey: usePermissionsFragment$key | null
  revisionId: GlobalID
  contentUsageId: GlobalID
  contentLabel: string
}

function QuizContentBanner({
  testid = "QuizContentBanner",
  contentUsageKey,
  revisionId,
  contentUsageId,
  contentLabel,
}: QuizContentBannerProps) {
  const classes = useStyles()
  const contentUsageDrawer = useContentUsageDrawer()
  const { goToQuestions, goToRetake } = useQuizEditorDrawer()
  const contentUsagePermissions = usePermissions(contentUsageKey)
  const hasReadPermissions = contentUsagePermissions.has("content.read")
  const userTimezone = useUserTimezone()

  const { submissions, totalCount: totalSubmissions } = useWebFormSubmissionsQuery({
    revisionId, // revision ID must be provided so learner can see their submission
    usageId: contentUsageId,
    include: hasReadPermissions,
    first: 2,
    orderBy: { field: "completed_at", direction: "DESC" },
  })

  const { submission: latestSubmission = null, result: latestResult = null } =
    submissions.length > 0 ? submissions[0] : {}
  const { submission: previousSubmission = null } =
    submissions.length > 1 ? submissions[1] : {}

  const contentUsage = useWebFormContentUsageQuery({ usageId: contentUsageId })
  const canCompleteCurriculum = contentUsagePermissions.has("curriculum.complete")

  const isPassFail = typeof contentUsage?.passPercentage === "number"
  const passPercentage = contentUsage?.passPercentage
  const cantStartOrResume =
    contentUsage?.module?.isCurriculumModule && !canCompleteCurriculum

  if (!hasReadPermissions) return null

  return (
    <ContentCTABannerTemplate
      testid={testid}
      message={renderMessage()}
      buttons={renderButtons()}
    />
  )

  function renderMessage() {
    const titleTestId = `${testid}.title`
    const bodyTestId = `${testid}.body`
    if (!latestSubmission)
      return (
        <>
          <DiscoText
            testid={`${titleTestId}.start`}
            variant={"body-md-600"}
          >{`Start ${contentLabel} 🎯`}</DiscoText>
          <DiscoText testid={`${bodyTestId}.start`}>
            {`You haven't started the ${contentLabel} yet, click the button to get started!`}
          </DiscoText>
        </>
      )

    if (latestSubmission.completedAt) {
      if (isPassFail) {
        if (latestSubmission?.hasPassed) {
          return (
            <>
              {latestResult && (
                <>
                  <DiscoText
                    testid={`${titleTestId}.result`}
                    variant={"heading-xl"}
                    color={"success.main"}
                  >
                    {`${latestResult.percentage}%`}
                  </DiscoText>
                  <DiscoText testid={`${bodyTestId}.result`} variant={"body-md-600"}>
                    {`Congratulations! You passed this ${contentLabel}.`}
                  </DiscoText>
                </>
              )}
              <DiscoText
                variant={"body-sm-500"}
              >{`You completed this ${contentLabel} on ${formatDateWithOptions({
                timeZone: userTimezone,
              })(new Date(latestSubmission.completedAt))}!`}</DiscoText>
            </>
          )
        }

        return (
          <>
            {latestResult && (
              <>
                <DiscoText
                  testid={`${titleTestId}.result`}
                  variant={"heading-xl"}
                  color={"error.main"}
                >
                  {`${latestResult.percentage}%`}
                </DiscoText>
                <DiscoText testid={`${bodyTestId}.result`} variant={"body-md-600"}>
                  {`Whoops! You failed this ${contentLabel}. You need ${passPercentage}% to pass.`}
                </DiscoText>
              </>
            )}
            <DiscoText variant={"body-sm-500"}>{`You ${
              isPassFail ? "submitted" : "completed"
            } this ${contentLabel} on ${formatDateWithOptions({
              timeZone: userTimezone,
            })(new Date(latestSubmission.completedAt))}`}</DiscoText>
          </>
        )
      }

      return (
        <>
          {latestResult && (
            <>
              <DiscoText
                testid={`${titleTestId}.result`}
                variant={"heading-xl"}
                color={"primary.main"}
              >
                {`${latestResult.percentage}%`}
              </DiscoText>
              <DiscoText
                testid={`${bodyTestId}.result`}
                variant={"body-md-600"}
              >{`You completed this ${contentLabel} with a score of ${latestResult.percentage}%`}</DiscoText>
            </>
          )}
          <DiscoText
            variant={"body-sm-500"}
          >{`You completed this ${contentLabel} on ${formatDateWithOptions({
            timeZone: userTimezone,
          })(new Date(latestSubmission.completedAt))}!`}</DiscoText>
        </>
      )
    }

    return (
      <>
        <DiscoText
          testid={`${titleTestId}.resume`}
          variant={"body-md-600"}
        >{`📌 Resume ${contentLabel}`}</DiscoText>
        <DiscoText testid={`${bodyTestId}.start`}>
          {"Continue where you left off"}
        </DiscoText>
      </>
    )
  }

  function renderButtons() {
    if (isPassFail) {
      // If no submission
      if (!latestSubmission) {
        return (
          <DisabledButtonTooltip>
            <DiscoButton
              testid={`${testid}.go-to-questions`}
              onClick={goToQuestions}
              color={"primary"}
              disabled={cantStartOrResume}
            >
              {`Start ${contentLabel}`}
            </DiscoButton>
          </DisabledButtonTooltip>
        )
      }

      // If first submission is in progress
      if (!latestSubmission?.completedAt && totalSubmissions <= 1) {
        return (
          <DisabledButtonTooltip>
            <DiscoButton
              testid={`${testid}.resume`}
              onClick={goToQuestions}
              color={"primary"}
              disabled={cantStartOrResume}
            >
              {`Resume ${contentLabel}`}
            </DiscoButton>
          </DisabledButtonTooltip>
        )
      }

      // If a retake is in progress
      if (!latestSubmission?.completedAt && totalSubmissions > 1) {
        return (
          <div className={classes.buttons}>
            <DiscoButton
              testid={`${testid}.go-to-previous-results`}
              onClick={goToPreviousResults}
              color={"grey"}
              variant={"outlined"}
              disabled={cantStartOrResume}
            >
              {"View Previous Results"}
            </DiscoButton>

            <DisabledButtonTooltip>
              <DiscoButton
                testid={`${testid}.resume`}
                onClick={goToResume}
                color={"primary"}
                disabled={cantStartOrResume}
              >
                {`Resume ${contentLabel}`}
              </DiscoButton>
            </DisabledButtonTooltip>
          </div>
        )
      }

      // If a submission has been passed
      if (latestSubmission?.hasPassed) {
        return (
          <div className={classes.buttons}>
            <DisabledButtonTooltip>
              <DiscoButton
                testid={`${testid}.go-to-retake`}
                onClick={goToRetake}
                color={"grey"}
                variant={"outlined"}
                disabled={cantStartOrResume}
              >
                {`Re-Take ${contentLabel}`}
              </DiscoButton>
            </DisabledButtonTooltip>

            <DiscoButton testid={`${testid}.go-to-results`} onClick={goToResults}>
              {`View Results`}
            </DiscoButton>
          </div>
        )
      }

      // If a submission has been failed
      return (
        <div className={classes.buttons}>
          <DiscoButton
            testid={`${testid}.go-to-previous-results`}
            onClick={goToResults}
            color={"grey"}
            variant={"outlined"}
          >
            {"View Results"}
          </DiscoButton>

          <DisabledButtonTooltip>
            <DiscoButton
              testid={`${testid}.go-to-retake`}
              onClick={goToRetake}
              color={"primary"}
              disabled={cantStartOrResume}
            >
              {`Re-Take ${contentLabel}`}
            </DiscoButton>
          </DisabledButtonTooltip>
        </div>
      )
    }

    if (latestSubmission?.completedAt) {
      return (
        <DisabledButtonTooltip>
          <DiscoButton
            testid={`${testid}.go-to-questions`}
            onClick={goToQuestions}
            color={"grey"}
            variant={"outlined"}
          >
            {`View ${latestResult ? "Results" : "Response"}`}
          </DiscoButton>
        </DisabledButtonTooltip>
      )
    }
    return (
      <DisabledButtonTooltip>
        <DiscoButton
          testid={`${testid}.go-to-questions`}
          onClick={goToQuestions}
          color={"grey"}
          variant={"outlined"}
          disabled={cantStartOrResume}
        >
          {latestSubmission ? `Resume ${contentLabel}` : `Start ${contentLabel}`}
        </DiscoButton>
      </DisabledButtonTooltip>
    )
  }

  function DisabledButtonTooltip({ children }: { children: React.ReactElement }) {
    return (
      <DiscoTooltip
        disabled={!cantStartOrResume}
        content={"This action cannot be taken while previewing."}
      >
        {children}
      </DiscoTooltip>
    )
  }

  function goToResults() {
    contentUsageDrawer.setParams({
      drawerQuizTab: "questions",
      submissionId: latestSubmission?.id,
    })
  }

  function goToPreviousResults() {
    contentUsageDrawer.setParams({
      drawerQuizTab: "questions",
      submissionId: previousSubmission?.id,
    })
  }

  function goToResume() {
    contentUsageDrawer.setParams({
      drawerQuizTab: "questions",
      submissionId: latestSubmission?.id,
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  buttons: {
    display: "flex",
    gap: theme.spacing(1),
  },
}))

export default Relay.withSkeleton({
  skeleton: () => <ContentCTABannerTemplateSkeleton />,
  component: QuizContentBanner,
})
