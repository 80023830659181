import ContentInstancesTooltip from "@/admin/content-library/ContentInstancesTooltip"
import { useContentUsageDrawerContext } from "@/content-usage/drawer/ContentUsageDrawerContext"
import CurriculumDrawerFooterContent from "@/content-usage/drawer/footer/CurriculumDrawerFooterContent"
import InlineContentDrawerSubmitButton from "@/content-usage/drawer/footer/InlineContentDrawerSubmitButton"
import { InlineContentDrawerFooterFragment$key } from "@/content-usage/drawer/footer/__generated__/InlineContentDrawerFooterFragment.graphql"
import { InlineContentDrawerFormStore } from "@/content-usage/drawer/InlineContentDrawerTemplate"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { useDrawerContext } from "@/core/context/DrawerContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import WarningIcon from "@assets/disco/icons/color-icons/warning-light.svg"
import {
  DiscoButton,
  DiscoIcon,
  DiscoIconButton,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import classnames from "classnames"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

export interface InlineContentDrawerFooterProps extends TestIDProps {
  form?: InlineContentDrawerFormStore
  contentUsageKey?: InlineContentDrawerFooterFragment$key | null
}

function InlineContentDrawerFooter({
  testid = "InlineContentDrawerFooter",
  form,
  contentUsageKey,
}: InlineContentDrawerFooterProps) {
  const drawer = useContentUsageDrawer()
  const { handleLeave } = useUnsavedChangesModalContext()
  const { isCurriculum } = useContentUsageDrawerContext()

  const isMobile = useIsMobile()
  const theme = useTheme()

  const { openDrawerSidebar } = useDrawerContext()

  const contentUsage = useFragment<InlineContentDrawerFooterFragment$key>(
    graphql`
      fragment InlineContentDrawerFooterFragment on ContentUsage {
        content {
          usages {
            totalCount
          }
          ...ContentInstancesTooltipFragment
        }
        ...CurriculumDrawerFooterContentFragment
        ...InlineContentDrawerSubmitButtonFragment
      }
    `,
    contentUsageKey || null
  )

  const totalUsages = contentUsage?.content.usages?.totalCount || 0
  const editingContent = Boolean(form?.state.mode === "edit" && form?.isChanged)
  const updatingMultipleUsages = totalUsages > 1 && editingContent

  const classes = useStyles()

  useEffect(() => {
    if (drawer.params.drawerTab === "comments" && isMobile) {
      openDrawerSidebar()
    }
  }, [drawer.params.drawerTab, openDrawerSidebar, isMobile])

  return (
    <div
      className={classnames(classes.footer, {
        [classes.multipleUsagesFooter]: updatingMultipleUsages,
      })}
    >
      <div className={classes.warningTextContainer}>
        {editingContent && (
          <>
            <div className={classes.unsavedChangesTextContainer}>
              <WarningIcon width={24} height={24} />
              <DiscoText color={"groovy.yellow.600"} variant={"body-sm"}>
                {"Unsaved changes"}
              </DiscoText>
            </div>

            {renderWarningText()}
          </>
        )}
      </div>
      <div
        className={classes.buttonsContainer}
        data-testid={`${testid}.buttonsContainer`}
      >
        {renderButtons()}
      </div>
    </div>
  )

  function renderWarningText() {
    if (form?.state.detachFromContent) {
      return (
        <div className={classes.duplicateContentText}>
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {"Duplicating generates a new piece of content"}
          </DiscoText>
          <DiscoTooltip
            content={
              "Newly duplicated content operates independently from its original and any other versions."
            }
          />
        </div>
      )
    }

    if (totalUsages > 1)
      return (
        <DiscoText
          className={classes.editsTextContainer}
          variant={"body-sm"}
          color={"text.secondary"}
        >
          {`Edits to this content will affect `}
          <ContentInstancesTooltip contentKey={contentUsage!.content}>
            <DiscoText variant={"body-sm"} color={"primary.main"}>
              {`${totalUsages} instances`}
            </DiscoText>
          </ContentInstancesTooltip>
        </DiscoText>
      )
    return null
  }

  function renderButtons() {
    if (form?.state.mode === "add" || (form?.state.mode === "edit" && form.isChanged)) {
      return (
        <>
          <DiscoButton
            color={"grey"}
            variant={"outlined"}
            onClick={handleCancel}
            testid={`${testid}.cancel`}
          >
            {"Cancel"}
          </DiscoButton>

          <InlineContentDrawerSubmitButton
            testid={testid}
            form={form}
            formId={"InlineContentDrawerForm"}
            contentUsageKey={contentUsage}
          />
        </>
      )
    }

    return (
      <>
        {isCurriculum && <CurriculumDrawerFooterContent contentUsageKey={contentUsage} />}
        {isMobile && (
          <DiscoIconButton
            className={classes.navIcon}
            onClick={openDrawerSidebar}
            color={theme.palette.text.primary}
          >
            <DiscoIcon icon={"list"} />
          </DiscoIconButton>
        )}
      </>
    )
  }

  function handleCancel() {
    if (form?.state.mode === "add") {
      handleLeave({
        onLeave: drawer.close,
      })
    } else {
      handleLeave({
        onLeave: resetForm,
      })
    }
  }

  function resetForm() {
    if (form) form.reset()
  }
}

export const CONTENT_DRAWER_FOOTER_HEIGHT = "80px"
export const CONTENT_DRAWER_FOOTER_USAGES_XS_HEIGHT = "140px"
export const CONTENT_DRAWER_FOOTER_USAGES_MD_HEIGHT = "115px"

const useStyles = makeUseStyles((theme) => ({
  footer: {
    borderTop: theme.palette.constants.borderSmall,
    height: CONTENT_DRAWER_FOOTER_HEIGHT,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2.5),
    position: "absolute",
    bottom: 0,
    zIndex: 2,

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 1.5),
    },
  },
  // Separated from footer class as breakpoints and styleIf do not work well together
  multipleUsagesFooter: {
    [theme.breakpoints.down("md")]: {
      height: CONTENT_DRAWER_FOOTER_USAGES_MD_HEIGHT,
      justifyContent: "space-between",
      padding: theme.spacing(2),
      flexDirection: "column",

      "& $buttonsContainer": {
        alignSelf: "flex-end",
      },
    },
    [theme.breakpoints.down("xs")]: {
      height: CONTENT_DRAWER_FOOTER_USAGES_XS_HEIGHT,

      "& $warningTextContainer": {
        flexDirection: "column",
        gap: theme.spacing(1),
        alignItems: "flex-start",
        alignSelf: "flex-start",
      },
    },
  },
  warningTextContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  unsavedChangesTextContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  editsTextContainer: {
    display: "flex",
    gap: theme.spacing(0.5),
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  duplicateContentText: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  navIcon: {
    border: `2px solid ${theme.palette.constants.stroke}`,
    borderRadius: theme.measure.borderRadius.big,
    width: theme.spacing(5),
    height: theme.spacing(5),
    padding: theme.spacing(1),
  },
}))

export default observer(InlineContentDrawerFooter)
