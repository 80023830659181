/**
 * @generated SignedSource<<b3ce5919769e63dae92d9d5ea90f0dd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTrackContentViewFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "useTrackContentViewFragment";
};
export type useTrackContentViewFragment$key = {
  readonly " $data"?: useTrackContentViewFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTrackContentViewFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useTrackContentViewFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "fe311117a4cc2bc8ddf803341e2dcfb8";

export default node;
