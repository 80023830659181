import { WebFormSubmissionsUtils } from "@/web-form/utils/webFormSubmissionsUtils"
import { DiscoIcon } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { TestIDProps } from "@utils/typeUtils"
import { Fragment } from "react"

type Props = TestIDProps & {
  toolbarState: WebFormSubmissionsUtils.ToolbarState
  handleOrderBy: WebFormSubmissionsUtils.ToolbarStateHandlers["handleOrderBy"]
  showResult: boolean
}

function QuizSubmissionsOrderOptions(props: Props) {
  const {
    testid = "QuizSubmissionsOrderOptions",
    toolbarState,
    handleOrderBy,
    showResult,
  } = props

  return (
    <>
      {WebFormSubmissionsUtils.ORDER_BY_OPTIONS.map((option) => {
        if (!showResult && option.field === "score")
          return <Fragment key={option.field} />
        return (
          <DiscoDropdownItem
            key={option.field}
            testid={`${testid}.order.option-${option.field}`}
            title={option.title}
            onClick={() => handleOrderBy({ field: option.field, title: option.title })}
            rightButton={
              toolbarState.orderBy?.field === option.field ? (
                <DiscoIcon
                  icon={
                    toolbarState.orderBy?.direction === "DESC"
                      ? "arrow-stem-down"
                      : "arrow-stem-up"
                  }
                  width={16}
                />
              ) : null
            }
          />
        )
      })}
    </>
  )
}

export default QuizSubmissionsOrderOptions
