import DeleteContentLabelButton from "@/content-usage/drawer/label/DeleteContentLabelButton"
import { ContentLabelSelectorDropdownListItemOverflowFragment$key } from "@/content-usage/drawer/label/__generated__/ContentLabelSelectorDropdownListItemOverflowFragment.graphql"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  testid: string
  contentLabelKey: ContentLabelSelectorDropdownListItemOverflowFragment$key
  className?: string
  onClickEdit?: VoidFunction
  hideDeleteButton?: boolean
}

function ContentLabelSelectorDropdownListItemOverflow({
  testid,
  contentLabelKey,
  className,
  onClickEdit,
  hideDeleteButton,
}: Props) {
  const contentLabel =
    useFragment<ContentLabelSelectorDropdownListItemOverflowFragment$key>(
      graphql`
        fragment ContentLabelSelectorDropdownListItemOverflowFragment on ContentLabel {
          id
          defaultContentTypes
        }
      `,
      contentLabelKey
    )

  const TESTID = `${testid}.overflow`

  const isDefaultLabel = JSON.parse(contentLabel.defaultContentTypes).length !== 0

  return (
    <DiscoMoreActionsDropdown
      key={testid}
      testid={testid}
      moreActionsButtonClassName={className}
      rotateIcon
      width={32}
      height={32}
    >
      <DiscoDropdownItem
        testid={`${TESTID}.actions-edit`}
        icon={"pencil"}
        title={"Edit"}
        onClick={onClickEdit}
      />
      {!hideDeleteButton && !isDefaultLabel && (
        <DeleteContentLabelButton
          testid={`${TESTID}.actions-delete`}
          contentLabelId={contentLabel.id}
        >
          {(props) => <DiscoDropdownItem icon={"trash"} title={"Delete"} {...props} />}
        </DeleteContentLabelButton>
      )}
    </DiscoMoreActionsDropdown>
  )
}

export default ContentLabelSelectorDropdownListItemOverflow
