/**
 * @generated SignedSource<<bfd3c478ff338410cc192c6297ac14d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InlineContentDrawerTemplate_ModuleContentUsageQuery$variables = {
  id: string;
};
export type InlineContentDrawerTemplate_ModuleContentUsageQuery$data = {
  readonly moduleContentUsage: {
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"InlineContentDrawerTemplate_ModuleContentUsageFragment">;
  } | null;
};
export type InlineContentDrawerTemplate_ModuleContentUsageQuery = {
  variables: InlineContentDrawerTemplate_ModuleContentUsageQuery$variables;
  response: InlineContentDrawerTemplate_ModuleContentUsageQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InlineContentDrawerTemplate_ModuleContentUsageQuery",
    "selections": [
      {
        "alias": "moduleContentUsage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InlineContentDrawerTemplate_ModuleContentUsageFragment"
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InlineContentDrawerTemplate_ModuleContentUsageQuery",
    "selections": [
      {
        "alias": "moduleContentUsage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entityId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "releasedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isReleased",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isLocked",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurriculumModule",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentUsageNodeConnection",
                    "kind": "LinkedField",
                    "name": "children",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4e1a9911363ed41d6f400de7e1a382f1",
    "id": null,
    "metadata": {},
    "name": "InlineContentDrawerTemplate_ModuleContentUsageQuery",
    "operationKind": "query",
    "text": "query InlineContentDrawerTemplate_ModuleContentUsageQuery(\n  $id: ID!\n) {\n  moduleContentUsage: node(id: $id) {\n    __typename\n    ... on ContentUsage {\n      id\n      ...InlineContentDrawerTemplate_ModuleContentUsageFragment\n    }\n    id\n  }\n}\n\nfragment InlineContentDrawerTemplate_ModuleContentUsageFragment on ContentUsage {\n  id\n  entity\n  entityId\n  releasedAt\n  isReleased\n  isLocked\n  content {\n    id\n    name\n    isCurriculumModule\n    children {\n      totalCount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ec350966d1db64619b4133167b166903";

export default node;
