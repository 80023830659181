import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButtonSkeleton } from "@disco-ui"
import { Skeleton } from "@material-ui/lab"
import { hexToHsl } from "@utils/color/colorUtils"
import { TestIDProps } from "@utils/typeUtils"

interface Props extends TestIDProps {
  message: React.ReactNode
  buttons?: React.ReactNode
}

function ContentCTABannerTemplate({
  testid = "ContentCTABannerTemplate",
  message,
  buttons,
}: Props) {
  const classes = useStyles()

  return (
    <div data-testid={testid} className={classes.container}>
      <div className={classes.banner}>
        <div className={classes.message}>{message}</div>
        {buttons && <div className={classes.buttons}>{buttons}</div>}
      </div>
    </div>
  )
}

export function ContentCTABannerTemplateSkeleton() {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.banner}>
        <div className={classes.message}>
          <Skeleton variant={"text"} />
          <Skeleton variant={"text"} />
        </div>
        <div className={classes.buttons}>
          <DiscoButtonSkeleton />
        </div>
      </div>
    </div>
  )
}

export default Relay.withSkeleton({
  skeleton: () => <ContentCTABannerTemplateSkeleton />,
  component: ContentCTABannerTemplate,
})

const useStyles = makeUseStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(3),
  },
  banner: {
    border: "double 1px transparent",
    borderRadius: theme.measure.borderRadius.big,
    // this is a gradient outline
    // first gradient layer is the background color of the card
    // second gradient layer creates the outline, which goes from the primary color, to a rotated hue based on the difference between the primary color and the hue from original design
    backgroundImage: () => {
      const hsl = hexToHsl(theme.palette.primary.main)
      const rotatedHue = hsl
        .map((value, i) => (i === 0 ? (value - 228) % 360 : `${value}%`))
        .join(", ")

      return `linear-gradient(${theme.palette.groovy.neutral[100]}, ${theme.palette.groovy.neutral[100]}), linear-gradient(51.49deg, ${theme.palette.primary.main} 0.05%, hsl(${rotatedHue}) 100.08%)`
    },
    backgroundOrigin: "border-box",
    backgroundClip: "padding-box, border-box",
    padding: theme.spacing(2.5),

    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    flexWrap: "wrap",
  },
  message: {
    flex: "1 1 auto",
  },
  buttons: {
    flex: "1 0 auto",
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    flexWrap: "wrap",
  },
}))
