import useQuizEditorDrawer from "@/content-usage/drawer/quizzes/editor/useQuizEditorDrawer"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"

function QuizReviewSubmissionsHeaderTabs({
  testid = "QuizReviewSubmissionsHeaderTabs",
}: TestIDProps) {
  const { goToSubmissions } = useQuizEditorDrawer()
  const { params } = useContentUsageDrawer()
  const isMobile = useIsMobile()

  return (
    <DiscoTabs
      testid={testid}
      tabVariant={"grey-track"}
      routes={[
        {
          label: "Overview",
          onClick: () => goToSubmissions("submissions-overview"),
          active: params.drawerQuizTab === "submissions-overview",
          testid: "overview",
        },
        {
          label: isMobile ? "Submissions" : "Individual Submissions",
          onClick: () => goToSubmissions("submissions-detail"),
          active: params.drawerQuizTab === "submissions-detail",
          testid: "detail",
        },
        {
          label: isMobile ? "Reports" : "Question Summaries",
          onClick: () => goToSubmissions("submissions-summaries"),
          active: params.drawerQuizTab === "submissions-summaries",
          testid: "summaries",
        },
      ]}
    />
  )
}

export default QuizReviewSubmissionsHeaderTabs
