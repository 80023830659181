import { ContentLabelUpdateFormFragment$key } from "@/content-usage/drawer/label/__generated__/ContentLabelUpdateFormFragment.graphql"
import { ContentLabelUpdateForm_UpdateLabelMutation } from "@/content-usage/drawer/label/__generated__/ContentLabelUpdateForm_UpdateLabelMutation.graphql"
import { useFormStore } from "@/core/form/store/FormStore"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoIcon, DiscoIconButton, DiscoInput } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface ContentLabelUpdateFormProps extends TestIDProps {
  contentLabelKey: ContentLabelUpdateFormFragment$key
  onClose: () => void
}

function ContentLabelUpdateForm({
  contentLabelKey,
  onClose,
  testid,
}: ContentLabelUpdateFormProps) {
  const classes = useStyles()

  const contentLabel = useFragment<ContentLabelUpdateFormFragment$key>(
    graphql`
      fragment ContentLabelUpdateFormFragment on ContentLabel {
        id
        label
      }
    `,
    contentLabelKey
  )

  const TESTID = `${testid}.item.${contentLabel.label}`

  const updateLabelForm = useFormStore<ContentLabelUpdateForm_UpdateLabelMutation>(
    graphql`
      mutation ContentLabelUpdateForm_UpdateLabelMutation(
        $input: UpdateContentLabelInput!
      ) {
        response: updateContentLabel(input: $input) {
          node {
            id
            label
            contents {
              edges {
                node {
                  id
                  label
                }
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      contentLabelId: contentLabel.id,
      label: contentLabel.label,
    }
  )

  return (
    <div className={classes.editContainer}>
      <DiscoInput
        value={updateLabelForm.state.label}
        onChange={handleLabelChange}
        data-testid={`${TESTID}.edit`}
        fullWidth
      />

      <div className={classes.editButtonsContainer}>
        <DiscoIconButton
          size={"small"}
          variant={"outlined"}
          testid={`${TESTID}.edit-confirm`}
          onClick={confirmEdit}
          height={40}
          width={40}
          shouldDisplaySpinner={updateLabelForm.isSubmitting}
        >
          <DiscoIcon icon={"check"} />
        </DiscoIconButton>

        <DiscoIconButton
          size={"small"}
          variant={"outlined"}
          testid={`${TESTID}.edit-cancel`}
          onClick={onClose}
          height={40}
          width={40}
        >
          <DiscoIcon icon={"close"} />
        </DiscoIconButton>
      </div>
    </div>
  )

  function handleLabelChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    updateLabelForm.state.label = e.target.value
  }

  async function confirmEdit() {
    const { didSave, response } = await updateLabelForm.submit(updateLabelForm.state)
    if (!didSave || !response?.node) return

    displaySuccessToast({
      testid: `${testid}.success-toast`,
      message: "Label updated!",
    })
    onClose()
  }
}

const useStyles = makeUseStyles((theme) => ({
  editContainer: {
    height: "48px",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  editButtonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))

export default observer(ContentLabelUpdateForm)
