import { InlineContentDrawerSubmitButtonFragment$key } from "@/content-usage/drawer/footer/__generated__/InlineContentDrawerSubmitButtonFragment.graphql"
import { InlineContentDrawerFormState } from "@/content-usage/drawer/InlineContentDrawerTemplate"
import useInlineContentDrawerContentLabel from "@/content-usage/drawer/useInlineContentDrawerContentLabel"
import ContentUtils from "@/content/util/contentUtils"
import FormStore from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import { WebFormSubmissionsUtils } from "@/web-form/utils/webFormSubmissionsUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import Form from "@components/form/Form"
import { DiscoButton, DiscoButtonSkeleton, DiscoDropdown, DiscoTooltip } from "@disco-ui"
import DiscoButtonsGroup from "@disco-ui/button/DiscoButtonsGroup"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"

interface Props extends TestIDProps {
  formId: string
  form: FormStore<Omit<InlineContentDrawerFormState, "moduleReleasedAt">, any>
  contentUsageKey: InlineContentDrawerSubmitButtonFragment$key | null
  variant?: "title" | "footer"
}

function InlineContentDrawerSubmitButton({
  testid,
  formId,
  form,
  variant = "footer",
  contentUsageKey,
}: Props) {
  const classes = useStyles({ variant })

  const contentUsage = useFragment<InlineContentDrawerSubmitButtonFragment$key>(
    graphql`
      fragment InlineContentDrawerSubmitButtonFragment on ContentUsage {
        entity
        content {
          type
          label
          contentLabelId
          usages {
            totalCount
          }
        }
      }
    `,
    contentUsageKey || null
  )
  // if there isn't a usage, it means we're creating the content
  const contentLabel = useInlineContentDrawerContentLabel(
    form.state.content.contentLabelId ?? "",
    contentUsage
  )

  // contentUsage will be null if the form is in "add" mode
  const totalUsages = contentUsage?.content.usages.totalCount ?? 1

  // if the user is editing a web form, there can't be submissions for that usage
  const isWebFormContent = ContentUtils.isWebFormContent(form.state.contentType)
  const hasQuestionChanges =
    isWebFormContent && Boolean(form.changedState.content?.webFormRevision)
  const usageSubmissions = WebFormSubmissionsUtils.useCount({
    contentUsageId: form.state.contentUsageId,
    webFormId: form.state.content.webFormRevision?.webFormId,
    include: form.state.mode === "edit" && hasQuestionChanges,
  })
  const allSubmissions = WebFormSubmissionsUtils.useCount({
    webFormId: form.state.content.webFormRevision?.webFormId,
    contentUsageId: form.state.contentUsageId,
    include: form.state.mode === "edit" && hasQuestionChanges,
    excludeUsageScope: true,
  })

  const isQuestionEditingDisabled = !!usageSubmissions && hasQuestionChanges
  // if there are no submissions for this usage, but there are submissions for other usages, the admin must detach the content to save question edits
  const mustDetachToEditQuestions =
    !usageSubmissions && !!allSubmissions && hasQuestionChanges

  return form.state.mode === "add" || totalUsages === 1 ? (
    <Form.SubmitButton id={formId} form={form} testid={`${testid}.save`}>
      {"Save"}
    </Form.SubmitButton>
  ) : (
    <DiscoDropdown
      testid={testid}
      shouldDisplaySpinner={form.isSubmitting}
      menuButton={({ onClick: expandDropdown }) => (
        <DiscoTooltip content={renderTooltip()}>
          <div>
            <DiscoButtonsGroup
              testid={"AutomationDrawerSubmitButton.button-group"}
              noPadding
              height={variant === "title" ? "36px" : "40px"}
              buttons={[
                {
                  uniqueKey: "submit-button",
                  content: (
                    <Form.SubmitButton
                      id={formId}
                      form={form}
                      testid={`${testid}.save`}
                      className={classes.button}
                      disabled={isSubmitDisabled()}
                    >
                      {getButtonText(form.state.detachFromContent)}
                    </Form.SubmitButton>
                  ),
                },
                {
                  uniqueKey: "dropdown-button",
                  content: (
                    <DiscoButton
                      testid={`${testid}.dropdown-button`}
                      className={classes.iconButton}
                      onClick={(e) => {
                        expandDropdown(e)
                      }}
                      leftIcon={"arrow-down"}
                    />
                  ),
                },
              ]}
            />
          </div>
        </DiscoTooltip>
      )}
    >
      <DiscoDropdownItem
        testid={`${testid}.save-all`}
        icon={"save"}
        title={getButtonText(false)}
        onClick={() => (form.state.detachFromContent = false)}
      />
      <DiscoDropdownItem
        testid={`${testid}.detach`}
        icon={"copy"}
        title={"Duplicate Content"}
        onClick={() => handleSelectDuplicate()}
        disabled={isQuestionEditingDisabled}
      />
    </DiscoDropdown>
  )
  function handleSelectDuplicate() {
    form.state.detachFromContent = true
  }

  function getButtonText(detachFromContent?: boolean | null) {
    if (detachFromContent) return "Duplicate"
    return totalUsages > 1 ? "Save All Instances" : "Save Changes"
  }

  function renderTooltip() {
    if (
      !form.state.detachFromContent &&
      (isQuestionEditingDisabled || mustDetachToEditQuestions)
    )
      return `Submissions exist for other instances of this ${contentLabel}. Duplicate the ${contentLabel} to edit the questions.`
    return ""
  }

  function isSubmitDisabled() {
    if (
      !form.state.detachFromContent &&
      (isQuestionEditingDisabled || mustDetachToEditQuestions)
    )
      return true
    return form.disabled || form.isSubmitting
  }
}

type StyleProps = {
  variant: Props["variant"]
}

const useStyles = makeUseStyles((theme) => ({
  iconButton: ({ variant }: StyleProps) => ({
    padding: theme.spacing(1),

    ...styleIf(variant === "title", {
      height: "36px",
      padding: theme.spacing(1, 0.75),
    }),
  }),
  button: ({ variant }: StyleProps) => ({
    ...styleIf(variant === "title", {
      height: "36px",
    }),
  }),
}))

export default Relay.withSkeleton({
  component: observer(InlineContentDrawerSubmitButton),
  skeleton: () => <DiscoButtonSkeleton width={"70px"} />,
})
