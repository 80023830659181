import { LabelData } from "@/core/context/LabelsContext"
import { LabelKind } from "@/core/context/__generated__/LabelsContext_Label.graphql"
import { ContentType } from "@/member-onboarding/__generated__/MemberOnboardingCompleteFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"

namespace ContentLabelUtils {
  export function getEquivalentLabelByContentLabelId(
    labels: Record<LabelKind, LabelData>,
    contentLabels: {
      defaultContentTypes: ContentType[]
      updatedAt: Date
      id: string
      label: string
    }[],
    contentLabelId: GlobalID
  ) {
    const cl = contentLabels.find((l) => l.id === contentLabelId)!
    const labelKinds = Object.keys(labels) as LabelKind[]
    const typeForMatchingLabel = labelKinds.find(
      (labelKind) => labels[labelKind as LabelKind].singular === cl.label
    )!
    return labels[typeForMatchingLabel]
  }

  export function getDefaultContentLabelIdByContentType(
    contentType: ContentType,
    contentLabels: { id: GlobalID; defaultContentTypes: ContentType[]; updatedAt: Date }[]
  ) {
    const contentLabel = contentLabels.find((cl) => {
      return cl.defaultContentTypes.includes(contentType)
    })
    if (!contentLabel) return null
    return contentLabel.id
  }
}

export default ContentLabelUtils
