/**
 * @generated SignedSource<<43c2089ca536f3c268e6a841a634f1a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateContentLabelInput = {
  organizationId: string;
  label: string;
};
export type ContentLabelSelectorDropdownList_CreateLabelMutation$variables = {
  input: CreateContentLabelInput;
};
export type ContentLabelSelectorDropdownList_CreateLabelMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly label: string;
      readonly organization: {
        readonly contentLabels: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly label: string;
              readonly " $fragmentSpreads": FragmentRefs<"ContentLabelSelectorDropdownListItemFragment">;
            };
          }>;
        };
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type ContentLabelSelectorDropdownList_CreateLabelMutation = {
  variables: ContentLabelSelectorDropdownList_CreateLabelMutation$variables;
  response: ContentLabelSelectorDropdownList_CreateLabelMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentLabelSelectorDropdownList_CreateLabelMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CreateContentLabelMutationResponse",
        "kind": "LinkedField",
        "name": "createContentLabel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentLabel",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentLabelNodeConnection",
                    "kind": "LinkedField",
                    "name": "contentLabels",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentLabelNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentLabel",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ContentLabelSelectorDropdownListItemFragment"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContentLabelSelectorDropdownList_CreateLabelMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CreateContentLabelMutationResponse",
        "kind": "LinkedField",
        "name": "createContentLabel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentLabel",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentLabelNodeConnection",
                    "kind": "LinkedField",
                    "name": "contentLabels",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentLabelNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentLabel",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "defaultContentTypes",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d46bd30985a118d8280dc41adfb5e631",
    "id": null,
    "metadata": {},
    "name": "ContentLabelSelectorDropdownList_CreateLabelMutation",
    "operationKind": "mutation",
    "text": "mutation ContentLabelSelectorDropdownList_CreateLabelMutation(\n  $input: CreateContentLabelInput!\n) {\n  response: createContentLabel(input: $input) {\n    node {\n      id\n      label\n      organization {\n        contentLabels {\n          edges {\n            node {\n              id\n              label\n              ...ContentLabelSelectorDropdownListItemFragment\n            }\n          }\n        }\n        id\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment ContentLabelSelectorDropdownListItemFragment on ContentLabel {\n  id\n  label\n  defaultContentTypes\n  ...ContentLabelUpdateFormFragment\n  ...ContentLabelSelectorDropdownListItemOverflowFragment\n}\n\nfragment ContentLabelSelectorDropdownListItemOverflowFragment on ContentLabel {\n  id\n  defaultContentTypes\n}\n\nfragment ContentLabelUpdateFormFragment on ContentLabel {\n  id\n  label\n}\n"
  }
};
})();

(node as any).hash = "4589fa2799a93b16d077bda1419b7693";

export default node;
