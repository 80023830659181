import ContentLabelSelectorDropdownList from "@/content-usage/drawer/label/ContentLabelSelectorDropdownList"
import { ContentLabelSelectorDropdownQuery } from "@/content-usage/drawer/label/__generated__/ContentLabelSelectorDropdownQuery.graphql"
import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { Popover, darken, useTheme } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { useMemo, useRef, useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

interface ContentLabelSelectorDropdownProps {
  form: ContentFormStore
  contentLabel: string
}

function ContentLabelSelectorDropdown({
  form,
  contentLabel,
}: ContentLabelSelectorDropdownProps) {
  const activeOrganization = useActiveOrganization()!
  const { organization } = useLazyLoadQuery<ContentLabelSelectorDropdownQuery>(
    graphql`
      query ContentLabelSelectorDropdownQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            ...ContentLabelSelectorDropdownListFragment
          }
        }
      }
    `,
    { id: activeOrganization.id },
    { fetchPolicy: "network-only" }
  )

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const classes = useStyles()
  const theme = useTheme()

  const popoverAnchorRef = useRef<HTMLDivElement | null>(null)

  const popoverAnchor = useMemo(() => {
    return <div ref={popoverAnchorRef} className={classes.anchorContainer} />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!organization) return null
  return (
    <>
      <div className={classes.container}>
        <DiscoText
          className={classes.contentLabelSelector}
          variant={"body-xs-600-uppercase"}
          color={"primary.main"}
          data-testid={`ContentLabelSelectorDropdown.editable.content-label`}
          onClick={() => setAnchorEl(popoverAnchorRef.current as HTMLDivElement)}
        >
          {contentLabel || "Pick a label"}
          <DiscoIcon
            className={classes.contentLabelChevron}
            color={theme.palette.primary.main}
            height={16}
            width={16}
            icon={"chevron"}
          />
        </DiscoText>
        {popoverAnchor}
      </div>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{ classes: { root: classes.popoverPaper } }}
        disablePortal
      >
        <ContentLabelSelectorDropdownList
          form={form}
          handleClose={handleClose}
          organizationKey={organization}
        />
      </Popover>
    </>
  )

  function handleClose() {
    setAnchorEl(null)
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    position: "relative",
  },
  anchorContainer: {
    position: "absolute",
    bottom: -theme.spacing(1),
    left: 0,
  },
  popoverPaper: {
    padding: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.big,
    maxHeight: "500px",
    minWidth: "200px",
  },
  contentLabelSelector: {
    padding: theme.spacing(0.5, 1),
    background:
      theme.palette.type === "light"
        ? theme.palette.primary.light
        : darken(theme.palette.primary.dark, 0.5),
    borderRadius: theme.measure.borderRadius.medium,
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
    width: "max-content",
    cursor: "pointer",
    transition: "background 0.2s",

    "&:hover": {
      background:
        theme.palette.type === "light"
          ? darken(theme.palette.primary.light, 0.04)
          : darken(theme.palette.primary.dark, 0.8),
    },
  },
  contentLabelChevron: {
    transform: "rotate(180deg)",
  },
}))

export default observer(ContentLabelSelectorDropdown)
